.ck.ck-content.ck-editor__editable {
  border: 1px solid var(--sea-blue-medium) !important;
  word-break: break-word !important;
}

.ck.ck-toolbar {
  background: white !important;
  border: 1px solid var(--sea-blue-medium) !important;
  border-bottom-width: 0 !important;

  &.ck-toolbar_vertical {
    border: none !important;
  }
}

.ck.ck-dropdown__panel {
  border: none !important;

  .ck.ck-toolbar {
    border: none !important;
  }
}

.ck.ck-button:active,
.ck.ck-button:focus,
a.ck.ck-button:active,
a.ck.ck-button:focus {
  border: none !important;
}

.ck.ck-list__item .ck-button:hover:not(.ck-disabled):not(.ck-on) {
  background: var(--sea-blue-light) !important;
}

.ck.ck-list__item .ck-button.ck-on {
  background: var(--sea-blue-dark) !important;
}

.ck.ck-color-grid__tile:focus:not(.ck-disabled),
.ck.ck-color-grid__tile:hover:not(.ck-disabled) {
  box-shadow: inset 0 0 0 1px var(--ck-color-base-background), 0 0 0 2px var(--sea-blue-dark) !important;
}

.ck.ck-button:not(.ck-disabled):not(.ck-on):not(.ck-color-grid__tile):hover,
a.ck.ck-button:not(.ck-disabled):not(.ck-on):not(.ck-color-grid__tile):hover {
  background: var(--sea-blue-light) !important;
}

.ck .ck-insert-table-dropdown-grid-box.ck-on {
  border-color: var(--sea-blue-dark) !important;
  background: var(--sea-blue-light) !important;
}

.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
  background: var(--sea-blue-light) !important;
}

.ck.ck-splitbutton.ck-splitbutton_open > .ck-button:not(.ck-on):not(.ck-disabled):not(:hover),
.ck.ck-splitbutton:hover > .ck-button:not(.ck-on):not(.ck-disabled):not(:hover) {
  background: var(--granite-gray-light) !important;
  border-color: var(--granite-gray-dark) !important;
}

.ck.ck-button,
a.ck.ck-button {
  border: none !important;
}

.ck.ck-character-grid .ck-character-grid__tile:focus:not(.ck-disabled),
.ck.ck-character-grid .ck-character-grid__tile:hover:not(.ck-disabled) {
  box-shadow: inset 0 0 0 1px var(--ck-color-base-background), 0 0 0 2px var(--sea-blue-dark) !important;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  box-shadow: none !important;
}

.marker-green {
  background-color: var(--ck-highlight-marker-green) !important;
}

.marker-yellow {
  background-color: var(--ck-highlight-marker-yellow) !important;
}

.marker-pink {
  background-color: var(--ck-highlight-marker-pink) !important;
}

.marker-blue {
  background-color: var(--ck-highlight-marker-blue) !important;
}

.pen-red {
  color: var(--ck-highlight-pen-red) !important;
  background-color: initial !important;
}

.pen-green {
  color: var(--ck-highlight-pen-green) !important;
  background-color: initial !important;
}

.ck.ck-input-text:focus {
  outline: none !important;
  border: 1px solid var(--sea-blue-dark) !important;
  box-shadow: var(--ck-focus-outer-shadow-geometry) var(--sea-blue-light), 0 0 !important;
}

.ck.ck-button.ck-button-save,
a.ck.ck-button.ck-button-save {
  color: var(--sea-blue-dark) !important;
}

.ck.ck-button.ck-button-cancel,
a.ck.ck-button.ck-button-cancel {
  color: var(--sea-blue-dark) !important;
}

.ck .ck-link_selected {
  background: var(--sea-blue-light) !important;
}

.ck a {
  color: var(--sea-blue-dark) !important;
}

.ck.ck-link-actions .ck-button.ck-link-actions__preview .ck-button__label {
  color: var(--sea-blue-dark) !important;
  opacity: 1 !important;
}
