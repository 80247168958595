@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

$true-blue-palette: (
  50: #e0e3ed,
  100: #b3bad1,
  200: #808cb2,
  300: #4d5e93,
  400: #263c7c,
  500: #001965,
  600: #00165d,
  700: #001253,
  800: #000e49,
  900: #000837,
  A100: #6e76ff,
  A200: #3b45ff,
  A400: #0815ff,
  A700: #000cee,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$granite-gray-palette: (
  50: #f2f3f4,
  100: #dfe1e5,
  200: #c9cdd3,
  300: #b3b8c1,
  400: #a3a9b4,
  500: #939aa7,
  600: #8b929f,
  700: #808896,
  800: #767e8c,
  900: #646c7c,
  A100: #f1f6fe,
  A200: #c0d6fd,
  A400: #8bb4ff,
  A700: #71a4ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat-palette($true-blue-palette);
$app-accent: mat-palette($granite-gray-palette);

// The warn palette is optional (defaults to red).
$app-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$app-theme: mat-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($app-theme);

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat-typography-config(
  $font-family: 'Apis-Regular',
);

// Override typography CSS classes (e.g., mat-h1, mat-display-1, mat-typography, etc.).
@include mat-base-typography($custom-typography);

// Override typography for a specific Angular Material components.
@include mat-checkbox-typography($custom-typography);

// Override typography for all Angular Material, including mat-base-typography and all components.
@include angular-material-typography($custom-typography);

// Override the typography in the core CSS.
@include mat-core($custom-typography);
