@font-face {
  font-family: 'Apis-Regular';
  src: url('../fonts/Apis-Regular.ttf');
}

@font-face {
  font-family: 'Apis-Medium';
  src: url('../fonts/Apis-Medium.ttf');
}

@font-face {
  font-family: 'Apis-Bold';
  src: url('../fonts/Apis-Bold.ttf');
}
