:root {
  --true-blue: #001965;
  --snow-white: #ffffff;
  --sea-blue-light: #e2effa;
  --sea-blue-medium: #99bded;
  --sea-blue-dark: #005ad2;
  --rose-pink-light: #fcf2f5;
  --rose-pink-medium: #f8dce6;
  --rose-pink-dark: #eea7bf;
  --granite-gray-light: #eff0f2;
  --granite-gray-medium: #d4d7dc;
  --granite-gray-dark: #939aa7;
  --sand-gray-light: #f7f6f5;
  --sand-gray-medium: #ebe8e4;
  --sand-gray-dark: #ccc5bd;
  --ocean-green-light: #dfeeee;
  --ocean-green-medium: #aad3d1;
  --ocean-green-dark: #2a918b;
}
