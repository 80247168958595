body {
  margin: 0;
  font-family: 'Apis-Regular';
}

button[disabled] {
  opacity: 0.6;
}

input {
  font-family: 'Apis-Regular';
}
